@import "../../utils/commons.scss";

.tablesContainer {
  //   width: 90%;
  //   margin: 2rem auto;

  .tabsContainer {
    @extend .flexRow;
    > div {
      > button:nth-of-type(1) {
        margin-right: 1rem;
      }
    }
  }

  .tabs {
    margin: 1rem 0;
    img {
      width: 30px;
    }
  }
}

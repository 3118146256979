*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Inter", sans-serif;
  font-family: Gilroy-400;
  letter-spacing: 1px;
  // color:white;
}

:root {
  --clr-primary: #643ad9;
  --clr-bg-light: #1f2226;
  --clr-bg-dark: #181a1c;
  --nav-height: 70px;
  --sidemenu-width: 100px;

  --fixed-width: 80%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid rgb(133, 131, 131);
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1f143f inset;
  transition: background-color 5000s ease-in-out 0s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}
p {
  margin-bottom: 0 !important;
}

@font-face {
  font-family: Gilroy-800;
  src: url("./assets/fonts/Gilroy/Gilroy-ExtraBold.ttf");
}

@font-face {
  font-family: Gilroy-700;
  src: url("./assets/fonts/Gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: Gilroy-500;
  src: url("./assets/fonts/Gilroy/Gilroy-Medium.ttf");
}

@font-face {
  font-family: Gilroy-400;
  src: url("./assets/fonts/Gilroy/Gilroy-Regular.ttf");
}

@font-face {
  font-family: Gilroy-300;
  src: url("./assets/fonts/Gilroy/Gilroy-Light.ttf");
}

.container {
  // background: var(--clr-bg-dark);
  // color: white;
  // margin-top: var(--nav-height);
  // height: calc( 100vh - var(--nav-height));
  // width: 100%;
}
